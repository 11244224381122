<template>
<div class="appointmentDetails">
  <van-icon class="fh" @click="$router.back(-1)" name="arrow-left" />

  <div class="top">
    <div class="bj">预约详情</div>
    <img class="tx" :src="'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/'+ ExData.ExpertstrImgUrl" alt="">
    <div class="right">
      <div class="ExName">
        <span>{{ExData.ExpertName}}</span>
        <img src="http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/wxxcx/rz.png" alt="">
      </div>
      <div class="ExZc">{{ExData.ExpertWorkTitle}}</div>
      <div style=" max-width: 200px;">{{ExData.ExpertMajor}}</div>
    </div>
  </div>

  <div class="zxr">咨询人：{{this.user.CustomerName}}</div>

  <div class="content">
    <div class="time">{{time}}</div>
    <div class="dzxq">
      <p class="dztitle">地址：</p>
      <div>
        <p>{{yyList[0].ShopFull.Shop.ShopHealthCheckName?yyList[0].ShopFull.Shop.ShopHealthCheckName:yyList[0].ShopFull.Shop.ShopName}}</p>
        <p class="zdz">{{yyList[0].ShopFull.Shop.ShopAddress}}</p>
      </div>
    </div>
    <ul>
      <li v-for="(item, idx) in yyList" :class="[(idx+1)%3==0?'nmar':'', idx==yyIdx?'xzyy':'']" @click="yyIdx = idx">
        <p>{{item.ScheduleStartTime}}-{{item.ScheduleEndTime}}</p>
        <p>接诊：{{item.ScheduleType}}</p>
        <p v-if="item.ScheduleCost != 0">排号费：{{item.ScheduleCost}}元</p>
        <span v-if="yyList.length > 1">{{item.ScheduleReservations - item.ScheduleReservationCount}}</span>
      </li>
    </ul>
  </div>

  <div class="yyBnt" @click="yy">立即预约</div>

  <van-overlay :show="show">
    <div style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;">
      <van-loading size="24px" color="#ffffff" >预约中...</van-loading>
    </div>
  </van-overlay>

  <van-overlay :show="showget">
    <div style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;">
      <van-loading size="24px" color="#ffffff" >加载中...</van-loading>
    </div>
  </van-overlay>

  <YYXQ v-if="showtX" :datas="xqdata"></YYXQ>
</div>
</template>

<script>
export default {
  name: "appointmentDetails",
  data() {
    return {
      ExData: {},
      user: {},
      time: '',
      shopId: '',
      yyList: [{
        ShopFull: {
          Shop: {}
        }
      }],
      yyIdx: -1,
      show: false,
      showget: false,
      xqdata: {},
      showtX: false
    }
  },
  created() {
    this.ExData = this.$store.state.Base.Expert
    this.user = this.$store.state.Base.user
    this.time = this.$route.query.time
    this.shopId = this.$route.query.shopId
    // console.log(this.time)
    this.getYyList()
    // console.log(this.shopId, this.ExData)
  },
  methods: {
    getYyList() {
      this.showget = true
      this._http2.post('https://interface2.visumall.com/Optometrist/Schedule.ashx?cmd=GetList', {
        "token":{"TimeSpan":1566827733,"sToken":"768DCAFFF869CD61372790C2738012EC"},
        "SearchCondition":{
          "IndexMin":0,
          "IndexMax":0,
          "PageIndex":1,
          "PageSize":10000,
          "ScheduleShopId": this.shopId ,
          "ScheduleExpertId": this.ExData.ExpertId,
          "ScheduleDateStart":this.time,
          "ScheduleDateEnd":this.time
        },
        "ReturnInfo":{"ShopFull":{}}
      })
      .then(res => {
        this.showget = false
        // console.log(res)
        if (res.GetListResult && res.GetListResult.length) {
          let arrs = res.GetListResult.map(item => {
            let str = item.ScheduleStartTime.toString()
            let js = item.ScheduleEndTime.toString()
            str = str.split('.')
            if (str.length > 1) {
              str = str[0] + ':30'
            }else {
              str = str[0] + ':00'
            }

            js = js.split('.')
            if (js.length > 1) {
              js = js[0] + ':30'
            }else {
              js = js[0] + ':00'
            }
            item.ScheduleStartTime = str
            item.ScheduleEndTime = js
            return item
          })
          this.yyList = [...arrs]
          // console.log(this.yyList)
        }
      })
    },

    yy() {
      if (this.yyIdx == -1) {
        return this.$dialog.alert({message: '请选择有效的预约时间段', theme: 'round-button'})
      }
      let date = new Date()
      let time = this.time + ' ' + this.yyList[this.yyIdx].ScheduleStartTime,
          shopid = this.yyList[this.yyIdx].ShopFull.Shop.ShopId,
          exId = this.ExData.ExpertId,
          csid = this.user.CustomerId,
          newTime = date.getFullYear() + '/' + (date.getMonth()+1) + '/' + date.getDay() + ' ' + date.getHours() + ':' +  date.getMinutes() + ':' + date.getSeconds(),
          scid = this.yyList[this.yyIdx].ScheduleID
      this.show = true
      this._http2.post('https://interface2.visumall.com/Customer/Oppointment.ashx?cmd=Insert', {
        "token":{"TimeSpan":1593173637,"sToken":"DBC7F06D27BA7F2FB54D5D63F11378D1"},
        "o":{
          "OppointmentId":"00000000-0000-0000-0000-000000000000",
          "OppointmentShopId": shopid,
          "OppointmentExpertId": exId,
          "OppointmentCustomerId": csid,
          "OppointmentScheduleId": scid,
          "OppointmentTargetChooseId":"00000000-0000-0000-0000-000000000000",
          "OppointmentState":"expected",
          "OppointmentOpenId":"",
          "OppointmentTime": time,
          "OppointmentAddTime": newTime,
          "OppointmentDoneTime":"",
          "OppointmentType":"WeiXinOppointment",
          "OppointmentSendTime":999,
          "OppointmentWaitTime":"",
          "OppointmentNumber":0,
          "Customer":null,
          "Company":null,
          "Shop":null,
          "Expert":null,
          "Schedule":null,
          "OppointmentEditeTime":"",
          "OppointmentEditeUserid":"00000000-0000-0000-0000-000000000000",
          "OppointmentRemarks":"",
          "OppointmentPayState":"",
          OppointmentHealthCheckId: this.$store.state.Base.parameters.HealthCheckId,
          "Oppointmentrice":0}
      })
      .then(res => {
        this.show = false
        let obj = {}
        // console.log(res)
        if (res.Result) {
          obj.sj = res.Result.OppointmentTime
          obj.ys = res.Result.ExpertName
          obj.tel = res.Result.Shop.ShopTel
          obj.gsdz = res.Result.Shop.ShopAddress
          obj.gsmc = res.Result.Shop.ShopHealthCheckName ? res.Result.Shop.ShopHealthCheckName : res.Result.Shop.ShopName
        }
        this.$dialog.alert({message: res.msg, theme: 'round-button'})
        .then(res => {
          this.xqdata = {
            gsmc: obj.gsmc,
            gsdz: obj.gsdz,
            gsdh: obj.tel,
            name: this.user.CustomerName,
            sj: obj.sj,
            ys: this.ExData.ExpertName,
            csid
          }
          this.showtX = true
        })
        // console.log(res)
      })
    }
  },
  components: {
    YYXQ: () => import('./AppointmentScreenshot')
  }
}
</script>

<style scoped lang="scss">
.appointmentDetails {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
}
.fh {
  position: absolute;
  z-index: 99;
  top: 10px;
  left: 10px;
  font-size: 20px;
}
.bj {
  width: 100vw;
  height: 130px;
  background: #BCE2FF;
  position: absolute;
  top: -59px;
  left: -5vw;
  z-index: -9;
  text-align: center;
  line-height: 50px;
  font-size: 19px;
  color: #fff;
}
.top {
  position: relative;
  width: calc(90vw - 40px);
  margin: 0 auto;
  margin-top: 59px;
  font-size: 16px;
  //text-align: center;
  padding: 20px 20px;
  background: #FFF0E0;
  display: flex;
  justify-content: start;
  border-radius: 13px;
  align-items: center;
  text-align: left;
  .tx {
    display: block;
    width: 60px;
    height: 60px;
    margin: 0 15px;
    border-radius: 50%;
  }
  .ExName {
    font-weight: bold;
    font-size: 17px;
    img {
      width: 20px;
      height: 20px;
      position: relative;
      top: 3px;
    }
  }
  .ExZc {
    display: inline-block;
    padding: 6px 20px;
    border-radius: 13px;
    background: #FFCCB7;
    color: #FF592D;
    margin: 13px 0;
  }
}

.zxr {
  width: calc(90vw - 30px);
  margin: 0 auto;
  background: #F5F5F5;
  border-radius: 10px;
  font-size: 15px;
  margin-top: 15px;
  padding: 15px;
}

.content {
  width: calc(90vw - 30px);
  margin: 0 auto;
  background: #F5F5F5;
  border-radius: 10px;
  font-size: 15px;
  margin-top: 15px;
  padding: 15px;
  .time {
    display: inline-block;
    text-align: center;
    padding: 10px 0;
    color: #00A8FF;
    border-bottom: 1px solid #00A8FF;
  }
  .dzxq {
    display: flex;
    justify-content: start;
    margin: 10px 0;
    margin-bottom: 25px;
    border-bottom: 1px solid #B5B5B5;
    //width: 60vw;
    .dztitle {
      width: 3em;
    }
    div {
      width: 70vw;
    }
    .zdz {
      color: #616161;
      padding-bottom: 19px;
    }
  }
}

ul {
  display: flex;
  flex-wrap: wrap;
  li {
    width: 87px;
    padding: 10px 4px;
    background: #fff;
    font-size: 14px;
    border: 1px solid #CFCFCF;
    border-radius: 7px;
    margin-right: 7px;
    margin-bottom: 15px;
    position: relative;
    text-align: center;
    span {
      display: block;
      position: absolute;
      width: 22px;
      height: 22px;
      border: 1px solid #D7D7D7;
      background: #fff;
      color: #000000;
      border-radius: 50%;
      text-align: center;
      line-height: 22px;
      top: -13px;
      right: 15px;
    }
  }
  .xzyy {
    background: #6EC69E;
    border: none;
    color: #fff;
  }
  .nmar {margin-right: 0;}
}

.yyBnt {
  color: #fff;
  width: 254px;
  font-size: 19px;
  font-weight: 600;
  text-align: center;
  padding: 10px 0;
  background: #6EC69E;
  border-radius: 7px;
  margin: 23px auto;
}
</style>

